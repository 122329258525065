import React, {useState, useEffect} from 'react';
import team from '../assets/team1.png';
import team1 from '../assets/team2.png';
import ImageTextSection from '../components/hex_content';
import Slideshow from '../components/slideshow';
import bee2 from '../assets/bee3.png';
import bee from '../assets/bee2.png';
import team2 from '../assets/team.jpeg';
import team3 from '../assets/team3.png';
import team4 from '../assets/team4.jpeg';
import teambg from '../assets/team_bg.png';
import employee1 from '../assets/testimonial1.jpg';
import employee2 from '../assets/testimonial2.jpg';
import employee3 from '../assets/testimonial3.jpg';
import employee4 from '../assets/testimonial4.jpeg';
import employee5 from '../assets/testimonial5.jpeg';
import employee6 from '../assets/testimonial6.jpeg';
import employee7 from '../assets/testimonial7.jpeg';
import employee8 from '../assets/employee8.jpeg';
import employee9 from '../assets/testimonial9.jpg';
import employee10 from '../assets/testimonial10.jpeg';
import employee11 from '../assets/testimonial11.jpeg';
import employee12 from '../assets/testimonial12.jpeg';
import employee13 from '../assets/employee12.jpg';
import employee14 from '../assets/testimonial13.jpg';
import Footer from '../components/footer';

const Team = () =>{
    const employees = [
        { image: employee8, name: 'Joseph Tan', role: 'Founder' },
        { image: employee1, name: 'Chi Seong', role: 'Senior Project Manager, Regional Development' },
        { image: employee2, name: 'Min Szan', role: 'Manager, Project Future (GQA)' },
        { image: employee3, name: 'William Khoo', role: 'Senior Manager, Customer Service Unit' },
        { image: employee4, name: 'Cheng Kim', role: 'Senior Project Manager, Strategic Planning' },
        { image: employee5, name: 'Zen', role: 'Manager, ACE' },
        { image: employee6, name: 'Shi Min', role: 'Manager, Customer Service Unit' },
        { image: employee7, name: 'Jing Han', role: 'Creative Director' },
        // { image: employee12, name: 'Shermaine', role: 'AI Explorer' },
        { image: employee14, name: 'Kester', role: 'Project Analyst, PEAK' },
        { image: employee10, name: 'Sahana', role: 'Data Science Explorer, ACE' },
        { image: employee11, name: 'Kristine', role: 'Service Engineer, CSU' },
        { image: employee13, name: 'Ling Pheng', role: 'Service Engineer, CSU' },
        // { image: employee9, name: 'Yao Wei', role: 'Project Support Engineer' },
    ];

    const [secondLineVisible, setSecondLineVisible] = useState(false);
    useEffect(() => {
    const timer = setTimeout(() => {
        setSecondLineVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
    }, []);
  
          
    return (
        <div>
        <div className="relative h-[50vh] lg:h-[90vh] md:h-[50vh] z-10 video-container">
            <img src={team} className='w-full h-full object-cover fullscreen-video' alt='team' />
            <div className="absolute top-[35%] left-[10%] right-[10%] w-[70%] text-white text-left overlay-text">
                <h1 className={`mb-2 font-bold lg:text-[5em] fade-in`}>
                Join us and be a part of
                </h1>
                <h1 className={`mb-2 font-bold lg:text-[5em] fade-in`}>
                    the Solution!
                </h1>
                <p className="text-[0.7em] lg:text-[2em] md:text-[1.5em] fade-in">About Us</p>
            </div>  
        </div>
        <ImageTextSection
            imageSrc={team1}
            aclassName={'img-bg1'}
            title='Our Values'
            subtitle='Building Blocks of Tomorrow'
            description='Join our unique and passionate team at ght, dedicated to revolutionizing healthcare through robotics and data intelligence. Our collaborative and creative culture values diversity and innovation, making us a leading force in the industry. Be part of our exciting journey towards a greater tomorrow.'
        />
        <div className="testimonials bg-cover bg-no-repeat bg-center relative">
            <div className="title flex flex-row items-center justify-center text-center text-white py-2 px-4 lg:px-0 mr-[10%]">
                <img src={bee2} alt="Bee 2" className="w-1/4 lg:w-1/5"/>
                <div>
                <p className="font-bold text-black">Our Team Players</p>
                <h1 className="font-bold lg:text-[2.5em] text-[#C30D3F]">The Beekeepers</h1>
                </div>
            </div>    
            <Slideshow
                employees={employees}
                additional=""
                textalign={"text-center"} />
        </div>  
        <div className="relative overflow-hidden md:pb-60 max-md:pb-20 -mt-20">
        <div className="absolute top-0 left-0 w-full h-full bg-red-600 transform skew-y-3 origin-left"></div>
        <div className="relative z-20 text-white px-4 lg:px-12 py-8">
            <div className="absolute top-5 left-0 w-full h-full">
            <img src={teambg} alt="Slanted Background" className="w-[75%] h-full object-cover pt-10 max-lg:hidden" />
            </div>
            <div className="relative z-20 lg:mx-20">
            <h1 className='lg:text-xl p-2'>The ght Ecosystem</h1>
            <p className="font-bold lg:text-4xl p-2">Team Events</p>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-2/3 p-2 md:w-2/3">
                <div className="relative">
                    <img src={team2} alt="Team 1" className="w-full object-cover rounded-[10px]" />
                    <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 rounded">
                    <p>2024 Batam Townhall</p>
                    </div>
                </div>
                </div>
                <div className="w-full lg:w-1/3 p-2 md:w-1/3 max-sm:hidden">
                <div className="relative">
                    <img src={bee} alt="Bee" className="w-[80%] object-cover" />
                </div>
                </div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-2/5 p-2">
                <div className="relative h-full flex">
                <img src={team1} alt="Team 2" className="w-full object-cover flex-grow rounded-[10px]" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 rounded">
                    <p>Team Building</p>
                </div>
                </div>
            </div>
            <div className="w-full lg:w-3/5 p-2">
                <div className="relative h-full flex">
                <img src={team3} alt="Team 3" className="w-full object-cover flex-grow rounded-[10px]" />
                <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 rounded">
                    <p>Xin Yuan Charity Event</p>
                </div>
                </div>
            </div>
            </div>
            <div className="flex flex-wrap">
            <div className="w-full lg:w-1/2 p-2 lg:order-2">
                <div className="relative">
                    <img src={team4} alt="Birthday Celebrations" className="w-full object-cover rounded-[10px]" />
                    <div className="absolute bottom-0 left-0 bg-black bg-opacity-50 text-white p-2 rounded">
                    <p>Birthday Celebrations</p>
                    </div>
                </div>
                </div>

                <div className="w-full lg:w-1/2 py-2 items-center justify-center lg:mt-20 lg:px-20 team-more">
                <h1 className="text-xl lg:text-5xl font-bold">...And More!</h1>
                <h3 className="text-md lg:text-xl">Stay tuned for more updates on our LinkedIn page!</h3>
                </div>
            </div>
            </div>
        </div>
        </div>
        <Footer noBackground={true} />
        </div>
    )

}

export default Team;